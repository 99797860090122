<template>
  <div>
    <div class="nav-bar">
      <van-icon
        name="arrow-left"
        size="20"
        color="#9B9B9B"
        class="nav-icon"
        @click="$router.go(-1)"
      />
      <span>我的消息</span>
    </div>
    <div class="page-layout">
      <van-swipe-cell v-for="item in 5" :key="item">
        <div class="list-news" @click="$router.push({ name: 'consult' })">
          <div class="head-img-box">
            <img src="~@/assets/common/icon-share@2x.png" class="head-img" />
          </div>
          <div class="news-info">
            <div class="shop-time">
              <span class="shop">钜宏家庭短号</span>
              <span>星期6</span>
            </div>
            <span>你有一条店铺信息</span>
          </div>
        </div>
        <template #right>
          <van-button square text="删除" type="danger" @click="delBtn" />
        </template>
      </van-swipe-cell>
    </div>
  </div>
</template>

<script>
import socket from "@/common/js/socket";
export default {
  name: "my-news",
  data() {
    return {};
  },
  methods: {
    delBtn() {
      // 删除消息列表
    },
  },
};
</script>

<style lang="less" scoped>
.nav-bar {
  position: relative;
  text-align: center;
  padding: 12px 0;
  background-color: #fff;
  font-size: 21px;
  border-bottom: 1px solid #f2f2f2;
  .nav-icon {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }
}
.page-layout {
  background-color: #fff;
  margin-top: 12px;
}
.list-news {
  display: flex;
  height: 78px;
  font-size: 16px;
  padding: 12px 12px 0 12px;
  box-sizing: border-box;
  &:active {
    background-color: #ededed;
  }
  .head-img-box {
    width: 52px;
    .head-img {
      width: 100%;
    }
  }
  .news-info {
    color: #6f6f6f;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 1px solid #ededed;
    padding-bottom: 12px;
    .shop-time {
      display: flex;
      justify-content: space-between;
      min-width: 328px;
      .shop {
        color: #000;
      }
    }
  }
}
.van-button {
  height: 100%;
}
</style>
